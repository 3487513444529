import '../styles/pages/corp.scss';
import * as React from "react"
import {HeadFC} from "gatsby"
import {useEffect, useState} from "react";
import {
    BigLogoImage, LoadingGif
} from "../images";
import MobileNavigation from '../components/MobileNavigation';
import MainFooter2 from '../components/MainFooter2';
import {toast, ToastContainer} from "react-toastify";
import Header from '../components/Header';
import TeachElement from '../components/TeachElement';

const TeachOnAeerPage = () => {
    const [isLoading, setLoading] = useState(true)
    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 600);
    }, []);

    return (
        <main className="teachBg">
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }
            <Header toggleMenu={toggleMenu} />

            <TeachElement />

            <MainFooter2 />

            {showMenu && (
                <MobileNavigation onClick={toggleMenu} relation="Home" />
            )}

            <ToastContainer />
        </main>
    )
}

export default TeachOnAeerPage

export const Head: HeadFC = () => <title>AEER Platform</title>
