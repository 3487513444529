import './styles.scss';
import React, {memo, useState} from 'react'

import {
    BigLogoImage,
    lang
} from "../../images";
import { Link } from 'gatsby';
import {AnchorLink} from "gatsby-plugin-anchor-links";

const TeachElement = () => {

    const [email, setEmail] = useState('');
    const [fullName, setFullname] = useState('');
    const [phone, setPhone] = useState('');
    const [job, setJob] = useState('');
    const [training, setTraining] = useState('');

    return (
        <>
            <div className="teach">
                <div className="container">
                    <div className="teachBox">
                        <div className="teachInfo">
                            <h2 className="teachTitle">COMING SOON!</h2>
                            <p className="teachText">Leave information to become one of the first our customers completely free</p>
                            <p className="teachSubtitle">So many reasons to start</p>
                            <ul className="teachList">
                                <li>Publish the course you want, in the way you want, and always have control of your own content.</li>
                                <li>Teach what you know and help learners explore their interests, gain new skills, and advance their careers.</li>
                                <li>Expand your professional network, build your expertise, and earn money on each paid enrollment.</li>
                            </ul>
                        </div>
                        <div className="teachForm">
                            <p className="teachFormTitle">Fill out your details</p>
                            <div className="teachInput">
                                <label htmlFor="">Email</label>
                                <input 
                                    type="email" 
                                    className="email" 
                                    placeholder="Please enter your email address" 
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="off"
                                    value={email} />
                            </div>
                            <div className="teachInput">
                                <label htmlFor="">Full Name</label>
                                <input type="text" className="user" placeholder="Enter please your full name"
                                    onChange={(e) => setFullname(e.target.value)}
                                    autoComplete="off"
                                    value={fullName} />
                            </div>
                            <div className="teachInput">
                                <label htmlFor="">Phone Number</label>
                                <input type="text" className="phone" placeholder="Enter please your phone number"
                                    onChange={(e) => setPhone(e.target.value)}
                                    autoComplete="off"
                                    value={phone} />
                            </div>
                            <div className="teachInput">
                                <label htmlFor="">Job Title</label>
                                <input type="text" className="job" placeholder="Enter please your job title"
                                    onChange={(e) => setJob(e.target.value)}
                                    autoComplete="off"
                                    value={job} />
                            </div>
                            <div className="teachTextarea">
                                <label htmlFor="">New Training</label>
                                <textarea name="" id="" placeholder="What you will teach"
                                    onChange={(e) => setTraining(e.target.value)}
                                    autoComplete="off"
                                    value={training}></textarea>
                            </div>
                            <button className="teachBtn">Get in touch</button>
                            <p className="teachRemark">By signing up, you agree to our terms and privacy policy. AEER does not share your data with third parties or use it for commercial purposes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(TeachElement);
